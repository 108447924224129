h4 {
  margin: 0;
}

.open {
  font-weight: 600;
  color: #3eba7e;
}

.closed {
  font-weight: 600;
  color: #e090b0;
}