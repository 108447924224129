button.markerColor {
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--primary-accent-color);
  cursor: pointer;
  border-radius: 50%;
}

button.markerColor.fav {
  background-color: var(--secondary-accent-color);
}

.marker {
  display: flex;
  gap: 0.25rem;
}

.marker span {
  font-weight: bold;
  cursor: pointer;
}

.marker.dark {
  color: var(--secondary-text-color);
  background-color: transparent;
}
