.mapboxgl-ctrl-top-right {
  padding-top: 3rem;
  margin-right: .25rem;
}

.cluster-marker {
  color: #fff;
  background: #3eba7e;
  box-shadow: 0 0 0 .2rem #66e4a7;
  border-radius: 50%;
  padding: .625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
