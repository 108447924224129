.wrapper {
  position: absolute;
  bottom: 1rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.closeButton {
  cursor: pointer;
  margin-left: auto;
  margin-right: 1rem;
}

.selectedPlaces {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  height: 8rem;
  z-index: 100;
  background-color: var(--primary-background-color);
  border-radius: 1rem;
  box-shadow: 0px .25rem .5rem rgba(0, 0, 0, 0.25);
}

.selectedPlaces a {
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-text-color);
}

.selectedPlaces h3 {
  margin: 1rem 0 0 0;
}

.description-container {
  display: flex;
}

.img-container img {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 1rem 0 0 1rem;
}

.description {
  margin-left: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description p {
  margin: 0 0 .25rem 0;
}

.description h3 {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.description a svg {
  width: 1.5rem;
  height: 1.5rem;
}

.favorite {
  margin-left: auto;
  margin-right: 1rem;
  margin-top: -.1875rem;
  cursor: pointer;
  fill: var(--primary-accent-color);
}

.favorite.fill {
  fill: var(--secondary-accent-color);
}

.dark {
  background-color: var(--secondary-background-color);
  fill: var(--secondary-text-color);
}

.closeButton.dark {
  background-color: inherit;
}

.selectedPlaces.dark .adress,
.selectedPlaces.dark h3 {
  color: var(--secondary-text-color);
}