.App {
  text-align: center;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

