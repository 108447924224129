.burger button {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 1.5rem;
  height: 16px;
  margin-right: 1rem;
  cursor: pointer;
}

.burger span {
  display: block;
  width: 1.5rem;
  height: 2px;
  background: var(--secondary-background-color);
}

.sidebar {
  position: fixed;
  top: 3rem;
  z-index: 1000;
  right: -300px;
  width: 300px;
  height: 100vh;
  background-color: var(--primary-background-color);
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, 0.25);
  transition: right 0.3s ease-in-out;
}

.sidebar.show {
  right: 0;
}

.sidebar-content {
  padding: 3rem 1.5rem;
}

.sidebar-content p {
  font-size: 1.2rem;
  overflow-wrap: break-word;
}

.sidebar-content a {
  text-decoration: none;
  cursor: pointer;
  color: var(--primary-accent-color);
}

.overlay {
  position: fixed;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
  transition: opacity 0.3s ease-in-out;
}

.overlay.show {
  display: block;
  opacity: 1;
}

.burger.dark, .sidebar.dark {
  background-color: var(--secondary-background-color);
  color: var(--secondary-text-color);
}

.burger.dark span {
  background: var(--primary-background-color);
}