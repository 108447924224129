.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
  fill: var(--primary-text-color);
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, 0.25);
  z-index: 9999999;
  width: 100%;
  height: 3rem;
}

.navigation svg {
  margin: auto 1rem;
}


h1 {
  margin: 0;
  margin-left: 1rem;
  font-size: 1.5rem;
}

.buttton-nav-group {
  display: flex;
  align-items: center;
}

/* Dark Theme */
.navigation.dark {
  color: var(--secondary-text-color);
  fill: var(--secondary-text-color);
  background-color: var(--secondary-background-color);
}